<template>
	<div>
		<Modal :options="{close:true}" @close="buscar_cliente_cancelar">
			<form @submit.prevent="buscar_cliente">
				<div class="title">Buscar cliente</div>
				<div class="body">
					<div class="row form-group">
						<label for="buscar_cliente.rfc" class="col-form-label col-sm-2">Buscar por Curp/RFC</label>
						<div class="col-sm-4"><input v-model="cliente_a_buscar" type="text" name="buscar_cliente.rfc" id="buscar_cliente.rfc" class="form-control"></div>
					</div>
					<div v-if="clientes_existentes.length > 0" class="row form-group">
						<table class="table">
							<thead>
								<th>&nbsp;</th>
								<th>Primer nombre</th>
								<th>Segundo nombre</th>
								<th>Primer apellido</th>
								<th>Segundo apellido</th>
								<th>RFC</th>
								<th>Curp</th>
								<th>Régimen</th>
								<th>Tipo</th>
							</thead>
							<tbody>
								<tr v-for="cliente in clientes_existentes">
									<td><Radio v-model="cliente_asignado" name="clientes_core" :id="'clientes_core_'+(cliente.id)" :value="$helper.toJson(cliente)" /></td>
									<td>{{ cliente.person.firstName }}</td>
									<td>{{ cliente.person.secondName ? cliente.person.secondName : '' }}</td>
									<td>{{ cliente.person.lastName }}</td>
									<td>{{ cliente.person.secondLastName ? cliente.person.secondLastName : '' }}</td>
									<td>{{ cliente.person.fiscalIdentity }}</td>
									<td>{{ cliente.person.personalIdentity }}</td>
									<td>{{ cliente.code == 'PF' ? 'Persona fisica' : 'Persona moral' }}</td>
									<td>{{ cliente.customerType }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-if="cliente_asignado" class="row form-group">
						<table class="table">
							<thead>
								<th>&nbsp;</th>
								<th>Folio</th>
								<th>Monto</th>
								<th>Tipo</th>
							</thead>
							<tbody>
								<tr>
									<td><Radio v-model="credito_asignado" name="folio_credito" id="folio_credito0" value="nuevo" /></td>
									<td colspan="3" style="text-align: center;">Crédito nuevo</td>
								</tr>
								<tr v-for="(credito, index) in cliente_asignado_data.creditos">
									<td>
										<Radio v-model="credito_asignado" name="folio_credito" :id="'folio_credito'+(index+1)" :value="$helper.toJson(credito)" />
									</td>
									<td>{{ credito.folio }}</td>
									<td>${{ $helper.moneyFormat(credito.amount) }}</td>
									<td>{{ credito.product.name }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-else class="row form-group">
						<div class="col-sm-12">No se encontraron clientes</div>
					</div>
				</div>
				<div class="footer">
					<div class="row form-group">
						<div class="col-sm-2 offset-sm-6"><button class="btn btn-primary btn-block">Buscar</button></div>
						<div class="col-sm-2"><button type="button" class="btn btn-success btn-block" :disabled="!cliente_asignado" @click="asignar_cliente_solicitud">Asignar</button></div>
						<div class="col-sm-2"><button type="button" class="btn btn-danger btn-block" @click="buscar_cliente_cancelar">Cancelar</button></div>
					</div>
				</div>
			</form>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'
	import Radio from '@/components/Form/Radio'

	import api from '@/apps/clientes/api/clientes'

	export default {
		components: {
			Modal, Radio
		}
		,props: {
			solicitud: {
				type: Object
				,default: () => ({
					curp: null
					,rfc: null
				})
			}
		}
		,data: () => {
			return {
				cliente_a_buscar: null
				,clientes_existentes: []
				,cliente_asignado: null
				,credito_asignado: null
			}
		}
		,mounted: function() {
			if (this.solicitud && this.solicitud.curp)
				this.cliente_a_buscar = this.solicitud.curp

			if (!this.cliente_a_buscar && this.solicitud && this.solicitud.rfc)
				this.cliente_a_buscar = this.solicitud.rfc
		}
		,methods: {
			buscar_cliente: async function() {
				try {
					this.clientes_existentes = (await api.buscar_cliente({search: this.cliente_a_buscar})).data;

					if (this.clientes_existentes.length == 0)
						this.$helper.showMessage('Sin coincidencias','No se encontraron clientes en el core que coincidan con el RFC o el Curp indicado','warn','alert');
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,buscar_cliente_cancelar: function() {
				this.$emit('close');
			}
			,asignar_cliente_solicitud: function() {
				
					let cliente = this.$helper.fromJson(this.cliente_asignado);
					this.$log.info('Cliente', cliente);

					if (cliente.id)
						this.solicitud.core_id = cliente.id;

					if (cliente.person.firstName)
						this.solicitud.primer_nombre = cliente.person.firstName;

					if (cliente.person.secondName)
						this.solicitud.segundo_nombre = cliente.person.secondName;

					if (cliente.person.lastName)
						this.solicitud.primer_apellido = cliente.person.lastName;

					if (cliente.person.secondLastName)
						this.solicitud.segundo_apellido = cliente.person.secondLastName;

					if (cliente.person.fiscalIdentity)
						this.solicitud.rfc = cliente.person.fiscalIdentity;

					if (cliente.person.personalIdentity)
						this.solicitud.curp = cliente.person.personalIdentity;

					// if (cliente.creditos && cliente.creditos.length > 0)
					// 	cliente.creditos.forEach(credito => {
							let credito = {};
							if (this.credito_asignado != 'nuevo')
								credito = this.$helper.fromJson(this.credito_asignado)
							// else
							// 	credito = {
							// 		folio: null
							// 		,core_id: null
							// 		,tipo_credito: 1
							// 		,moneda: 1
							// 		,monto_credito: null
							// 		,monto_deposito: null
							// 		,fecha_inicio: null
							// 		,fecha_fin: null
							// 		,plazo: null
							// 		,cuotas: null
							// 		,meses: null
							// 		,tasas_interes_ordinario: null
							// 		,tasa_interes_moratorio: null
							// 		,interes_genera_iva: null
							// 		,cuenta_financiera: null
							// 		,cuenta_pagos: null
							// 		,seguros: []
							// 		,fondeadores: []
							// 		,estatus: null
							// 	}

							let tmp = {
								folio: credito.folio ? credito.folio : null
								,core_id: credito.id ? credito.id : null
								,tipo_credito: 1
								,moneda: 1
								,monto_credito: credito.amount ? credito.amount : null
								,monto_deposito: credito.disbursementToAmount ? credito.disbursementToAmount : null
								,fecha_inicio: credito.startDate ? credito.startDate : null
								,fecha_fin: credito.endDate ? credito.endDate : null
								,plazo: credito.term ? credito.term : null
								,cuotas: credito.numAmortizations ? credito.numAmortizations : null
								,meses: null
								,tasas_interes_ordinario: credito.interestRate ? credito.interestRate : null
								,tasa_interes_moratorio: credito.arrearsRate ? credito.arrearsRate : null
								,interes_genera_iva: credito.generateTax ? credito.generateTax : null
								,cuenta_financiera: null
								,cuenta_pagos: null
								,seguros: []
								,fondeadores: []
								,estatus: null
							};

							if (credito.funders) {
								credito.funders.forEach(fondeador => {
									tmp.fondeadores.push({
										fondeador_id: fondeador.id
										,porcentaje: fondeador.percentage
									})
								})
							}

							this.solicitud.credito = tmp;
						// });

				this.buscar_cliente_cancelar();
			}
		}
		,computed: {
			cliente_asignado_data: function() {
				return this.$helper.fromJson(this.cliente_asignado);
			}
		}
	}
</script>

<style lang="scss">
	.table {
		width: 100%;

		thead {
			background-color: #333333 !important;

			th {
				padding: 5px 10px;
			}
		}

		tbody {
			tr:nth-child(2n+0) {
				background-color: #E6E6E6;
			}

			tr {
				td {
					padding: 5px 10px;
				}
			}
		}
	}

	.table-clientes {
	}
</style>